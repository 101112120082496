<template>
	<div class="mt-3">
		<b-form @submit.prevent="getAWB">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="1" autocomplete="off" ref="lm_or_hawm" @keydown.tab="getAWB" autofocus v-model="dhawb" placeholder="Enter DHL-AWB"></b-form-input>
					</div>
                </div>
            </div>

			<div class="row">
				<div class="col-sm-5 m-auto">
					<div v-if="isBusy" class="text-center">
						<b-skeleton animation="wave" width="50%"></b-skeleton>
					</div>
					<ul v-else-if="awbs.length > 0">
						<li v-for="lm in awbs" :key="lm"> {{lm}} </li>
					</ul>
				</div>
			</div>

		</b-form>
	</div>
</template>

<script>
export default {
	name: "DHLAWB",
	title: "DHL-AWB",
	data() {
		return {
			dhawb: '',
			awbs: [],
			isBusy: false
		}
	},
	methods: {
		async getAWB() {
			try {
				this.isBusy = true
				const res = await this.axios.post(`/dhl-awb`, {awb: this.dhawb})
				if(res.data.success) {
					this.awbs = res.data.awb
				}
				this.isBusy = false
			} catch (err) {
				this.dhawb = ''
				console.error(err)
				this.isBusy = false
			}
		}
	}
}
</script>